import request from '@/utils/request'

// 获取表头
export function getAdminFieldIndex(params) {
    return request({
      url: 'admin/field/index',
      method: 'GET',
      params
    })
  }
  // 更新字段表信息
  export function putAdminFieldIndex(data) {
    return request({
      url: 'admin/field/save',
      method: 'PUT',
      data
    })
  }
  